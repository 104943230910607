import PropTypes from 'prop-types';
import React from 'react';
import {Button, Grid, Dialog} from '@material-ui/core';
import Close from './close-btn.png';
import bindAll from 'lodash.bindall';
import {withStyles} from '@material-ui/core/styles';
import parse from 'html-react-parser';

const styles = {
    main: {
        width: '874px',
        height: 'auto',
        padding: '20px 30px'
    },
    heading: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '12px',
        color: '#707070'
    },
    close: {
        position: 'absolute',
        top: '20px',
        right: '2px'
    },
    instructionsWrapper: {
        marginTop: '20px'
    },
    points: {
        color: '#959595',
        fontSize: '14px',
        wordBreak: 'break-word'
    }
};

class InstructionsModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose'
        ]);
    }

    handleClose () {
        this.props.onHandleClose();
    }

    render () {
        const {main, close, heading, instructionsWrapper } = this.props.classes;
        const instructions = this.props.instructions;

        return (
            <Dialog
                open={this.props.isInstructionsOpen}
                maxWidth={'md'}
                onClose={this.handleClose}
            >
                <Grid container>
                    <Grid item>
                        <div className={main}>
                            <div>
                                <div className={heading}>
                                    {'Instructions'}
                                </div>
                                <Button
                                    variant='text'
                                    className={close}
                                    onClick={this.handleClose}
                                >
                                    <img width='15px' src={Close} alt='close' />
                                </Button>
                            </div>
                            <div className={instructionsWrapper}>
                                {instructions ? parse(instructions) : null}
                            </div>
                        </div>
                    </Grid>
                </Grid>
        </Dialog>
    )
}
};

InstructionsModal.propTypes = {
    isInstructionsOpen: PropTypes.bool,
    onHandleClose: PropTypes.func.isRequired
};

export default withStyles(styles)(InstructionsModal);
