import PropTypes from 'prop-types';
import React from 'react';
import {Button, Grid, Dialog} from '@material-ui/core';
import Close from './close-btn.png';
import bindAll from 'lodash.bindall';
import {withStyles} from '@material-ui/core/styles';
import play from './play_video.png';

const styles = {
    main: {
        width: '874px',
        height: 'auto',
        padding: '20px 30px'
    },
    heading: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '12px',
        color: '#707070'
    },
    close: {
        position: 'absolute',
        top: '20px',
        right: '2px'
    },
    outputWrapper: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    image: {
        width: '90%'
    }
};

class DesiredOutputModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose'
        ]);
    }

    handleClose () {
        this.props.onHandleClose();
    }

    render () {
        const {main, close, heading, outputWrapper, image } = this.props.classes;
        const desiredOutput = this.props.desiredOutput;
        const fileExtension = desiredOutput && desiredOutput.split(".").slice(-1)[0];
        return (
            <Dialog
                open={this.props.isOutputOpen}
                maxWidth={'md'}
                onClose={this.handleClose}
            >
                <Grid container>
                    <Grid item>
                        <div className={main}>
                            <div>
                                <div className={heading}>
                                    {'Desired Output'}
                                </div>
                                <Button
                                    variant='text'
                                    className={close}
                                    onClick={this.handleClose}
                                >
                                    <img width='15px' src={Close} alt='close' />
                                </Button>
                            </div>
                            <div className={outputWrapper}>
                            {fileExtension === 'mp4' ?
                                    <video width="480" height="440" controls>
                                        <source src={desiredOutput ? desiredOutput : play} type="video/mp4" />
                                    </video>
                                  : (fileExtension === "png" ||
                                  fileExtension === "jpeg" ||
                                  fileExtension === "jpg" ||
                                  fileExtension === "gif") ?
                                <img
                                    src={desiredOutput ? desiredOutput : play}
                                    alt="desiredOutput"
                                    className={image}
                                />:
                                <iframe
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${desiredOutput}`}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                              ></iframe>
        }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

DesiredOutputModal.propTypes = {
    desiredOutput: PropTypes.string,
    isOutputOpen: PropTypes.bool,
    onHandleClose: PropTypes.func.isRequired
};

export default withStyles(styles)(DesiredOutputModal);
