import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';
import downloadBlob from '../lib/download-blob';
import {API} from '../services/api-service';
import swal from '@sweetalert/with-react';
import Cookies from "js-cookie"
/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3Downloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3Downloader>
 */
class SB3Downloader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'downloadProject'
        ]);
    }
     getBrowserDetails () {
        const userAgent = navigator.userAgent;
        const browser = {};
    
        if (userAgent.match(/chrome|chromium|crios/i)) {
          browser.name = "chrome";
          browser.version = `Chrome v${userAgent.split("Chrome/")[1]}`;
        } else if (userAgent.match(/firefox|fxios/i)) {
          browser.name = "firefox";
          browser.version = `Firefox v${userAgent.split("Firefox/")[1]}`;
        } else if (userAgent.match(/safari/i)) {
          browser.name = "safari";
          browser.version = `Safari v${userAgent.split("Safari/")[1]}`;
        } else if (userAgent.match(/edg/i)) {
          browser.name = "edge";
          browser.version = `Edg v${userAgent.split("Edg/")[1]}`;
        } else {
          browser.name = "No browser detected";
          browser.version = "No Version detected";
        }
        return browser;
      };

    logScratch () {
        const browser = this.getBrowserDetails();
        API.post('/sandboxExecution', {
            language: 'scratch',
            status: 'started',
            platform: 'scartch_ui',
            browser: browser.name,
            browserVersion: browser.version,
            vncId: null,
            executionId: null,
            frontEndSection: Cookies.get('type')
        },
        )
            .then(res => {
                Cookies.set('executionId', res.data.id, {
                    path: '/'
                });
            })
            .catch(e => {
                console.error(e);
            });
    }

    logScratchSuccess () {
        API.put('/sandboxExecution', {
            id: Cookies.get('executionId'),
            status: "completed",
        })
            .then(res => {
              console.log(res);
            })
            .catch((e) => {
              console.error(e);
            });
    }

    trimFileName (name) {
        if (name) {
            if (name.includes('.')) {
                const parts = name.split('.');
                return parts[0];
            }
            return name;
        }
        return '';
    }
    downloadProject (fileId) {
        if (fileId){
            if (Cookies.get('type') === 'personalCollection' || Cookies.get('role') === 'mentor' || Cookies.get('type') === 'admin') {
                this.logScratch();
                this.props.saveProjectSb3().then(content => {
                    const filename = this.trimFileName(this.props.projectFilename);
                    const formData = new FormData();
                    formData.append('file', content, filename);
                    formData.append('language', 'scratch');
                    formData.append('fileId', Cookies.get('fileId'));
                    formData.append('type', Cookies.get('type'));
                    API.post('/updateScratchFile', formData).then(res => {
                        swal(
                            {
                                title: 'File Saved Successfully',
                                icon: 'success'
                            }
                        );
                        this.logScratchSuccess();
                    })
                        .catch(error => {
                            let title;
                            if (error.response.status === 401) {
                                title = 'Login expired. Please go back to Workbench.';
                            } else {
                                title = 'Unable to save file';
                            }
                            swal(
                                {
                                    title: title,
                                    icon: 'error'
                                }
                            );
                            console.error('Error While Saving File', error);
                        });
                
                // if (this.props.onSaveFinished) {
                //     this.props.onSaveFinished();
                // }
                // downloadBlob(this.props.projectFilename, content);
            });
            } else {
                swal(
                    {
                        title: 'You do not have permission to save this file',
                        icon: 'error'
                    }
                );
            }
        } else {
            this.props.saveProjectSb3().then(content => {
                const formData = new FormData();
                const filename = this.trimFileName(this.props.projectFilename);
                formData.append('file', content, filename);
                formData.append('language', 'scratch');
                API.post('/uploadScratchFile', formData).then(res => {
                    const lsOb = JSON.parse(localStorage.getItem("sandbox_user"));
                    lsOb.fileId = res.data.fileId;
                    lsOb.type = res.data.type;
                    Cookies.set('fileId', res.data.fileId, {
                        path: '/',
                        domain: '.codeyoung.com'
                    });
                    Cookies.set('type', 'personalCollection', {
                        path: '/',
                        domain: '.codeyoung.com'
                    });
                    Cookies.set('fileName', filename, {
                        path: '/',
                        domain: '.codeyoung.com'
                    });
                    localStorage.setItem('sandbox_user', JSON.stringify(lsOb));
                    swal(
                        {
                            title: 'File Saved Successfully',
                            icon: 'success'
                        }
                    );
                    this.logScratchSuccess();
                })
                    .catch(error => {
                        console.error('Error While Saving File', error);
                    });
            });
        }
    }
    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.downloadProject
        );
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

SB3Downloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func
};
SB3Downloader.defaultProps = {
    className: ''
};

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState)
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(SB3Downloader);
