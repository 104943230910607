import PropTypes from 'prop-types';
import React from 'react';
import {Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@material-ui/core';
import bindAll from 'lodash.bindall';
import {withStyles} from '@material-ui/core/styles';
import Close from '../exercises/close-btn.png';

const styles = {
    main: {
        width: '400px',
        // height: 'auto',
        // padding: '20px 30px'
    },
    heading: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '12px',
        color: '#707070'
    },
    close: {
        position: 'absolute',
        top: '20px',
        right: '2px'
    },
    outputWrapper: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    image: {
        width: '90%'
    }
};

class FilenameModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleChange',
            'handleSave'
        ]);
    }

    handleClose () {
        this.props.onHandleClose();
    }

    handleSave () {
        this.props.onSave();
    }

    handleChange (e) {
        const value = e.target.value;
        this.props.onFileNameChange(value);
    }

    render () {
        const {main, close, heading, outputWrapper, image } = this.props.classes;
        return (
            <Dialog open={this.props.isFilenameOpen} onClose={this.handleClose}>
              <div className={main}>
                <DialogTitle>Name your Scratch project</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="File Name"
                        type="fileName"
                        fullWidth
                        variant="standard"
                        onChange={this.handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSave}>Save</Button>
                    <Button onClick={this.handleClose}>Close</Button>
                </DialogActions>
                </div>
            </Dialog>
        );
    }
}

FilenameModal.propTypes = {
    // desiredOutput: PropTypes.string,
    isFilenameOpen: PropTypes.bool,
    onHandleClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onFileNameChange: PropTypes.func.isRequired
};

export default withStyles(styles)(FilenameModal);
