const SET_EXERCISE_CONTENT = 'scratch-gui/exercise/SET_EXERCISE_CONTENT';

// we are initializing to a blank string instead of an actual title,
// because it would be hard to localize here
const initialState = {};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_EXERCISE_CONTENT:
        return action.exercise;
    default:
        return state;
    }
};
const setExerciseContent = exercise => ({
    type: SET_EXERCISE_CONTENT,
    exercise: exercise
});

export {
    reducer as default,
    initialState as exerciseInitialState,
    setExerciseContent
};
