import classNames from 'classnames';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';
import React from 'react';
import swal from '@sweetalert/with-react';
import Cookies from 'js-cookie';

import VM from 'scratch-vm';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import CommunityButton from './community-button.jsx';
import ShareButton from './share-button.jsx';
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import LanguageSelector from '../../containers/language-selector.jsx';
import SaveStatus from './save-status.jsx';
import ProjectWatcher from '../../containers/project-watcher.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import ProjectTitleInput from './project-title-input.jsx';
import AuthorInfo from './author-info.jsx';
import AccountNav from '../../containers/account-nav.jsx';
import LoginDropdown from './login-dropdown.jsx';
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import DeletionRestorer from '../../containers/deletion-restorer.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';
import {API} from '../../services/api-service';

import {openTipsLibrary} from '../../reducers/modals';
import {setPlayer} from '../../reducers/mode';
import ExerciseModal from '../exercises/exercise-modal.jsx';
import InstructionModal from '../exercises/instructions-modal.jsx';
import DesiredOutputModal from '../exercises/desired-output-modal.jsx';
import SuccessModal from '../exercises/success-modal.jsx'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {setExerciseContent} from '../../reducers/exercise';

import ReplayIcon from '@material-ui/icons/Replay';
import DownloadOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ArticleOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import OutputOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen
} from '../../reducers/menus';

import collectMetadata from '../../lib/collect-metadata';

import styles from './menu-bar.css';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import mystuffIcon from './icon--mystuff.png';
import profileIcon from './icon--profile.png';
import remixIcon from './icon--remix.svg';
import dropdownCaret from './dropdown-caret.svg';
import languageIcon from '../language-selector/language-icon.svg';
import aboutIcon from './icon--about.svg';

import scratchLogo from './scratch-logo.svg';

import sharedMessages from '../../lib/shared-messages';
import downloadBlob from '../../lib/download-blob'
import FilenameModal from '../auto-save/filename-modal.jsx';
import {setProjectTitle} from '../../reducers/project-title';

const ariaMessages = defineMessages({
    language: {
        id: 'gui.menuBar.LanguageSelector',
        defaultMessage: 'language selector',
        description: 'accessibility text for the language selection menu'
    },
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    }
});

const role = Cookies.get('role');

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, isRtl, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

const AboutButton = props => (
    <Button
        className={classNames(styles.menuBarItem, styles.hoverable)}
        iconClassName={styles.aboutIcon}
        iconSrc={aboutIcon}
        onClick={props.onClick}
    />
);

AboutButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickNew',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleKeyPress',
            'handleLanguageMouseUp',
            'handleRestoreOption',
            'getSaveToComputerHandler',
            'restoreOptionMessage',
            'handleDownloadProject',
            'handleClose',
            'onInstructionsClick',
            'handleCloseInstructions',
            'onDesiredOutputClick',
            'handleCloseDesiredOutput',
            'onCompletedClick',
            'handleCloseSuccess',
            'handleNextExercise',
            'handleSaveExercise',
            'handleFilenameClose',
            'handleSave',
            'handleFileName',
            'autoSave',
            'startInterval',
            'handleVisibilityChange',
            'handleReAttemept'
            // 'logScratch',
            // 'getBrowserDetails'
        ]);
        this.state = {
            isCompleted: false,
            isExerciseOpen: false,
            isFilenameOpen: false,
            isInstructionsOpen: false,
            isOutputOpen: false,
            isSuccessOpen: false,
            exerciseResponse: {},
            markAsCompletedStatus: false,
            type: Cookies.get('type'),
            exerciseId: '',
            fileName: '',
            executionId: ''
        };
        this.autoSaveInterval = null;
    }
    componentDidMount () {
        const url = window.location.href
        const newUrl = new URL(url);
        const executionId = newUrl.searchParams.get('executionId');
        const exerciseId = newUrl.searchParams.get('exerciseId');
        const name = Cookies.get('fileName') ? Cookies.get('fileName') : ''
        // If execution id is not present in the url then return to sandbox website.
        if (executionId) {
            API.put("/sandboxExecution", {
                id: executionId,
                status: "completed",
                community: 1
            })
                .then((res) => {})
                .catch((e) => {
                    console.error(e);
                });
        } else {
            window.location.href = "https://sandbox.codeyoung.com/"
        }

        this.setState({
            exerciseId: exerciseId,
            fileName: name,
            executionId: executionId
        });
        document.addEventListener('keydown', this.handleKeyPress);
        const type = Cookies.get('type');
        if (type === 'exercise') {
            API.post('/exerciseStatus', {
                fileId: Cookies.get('fileId'),
                type
            }).then(res => {
                this.setState({isCompleted: Boolean(parseInt(res.data.completionStatus, 10))});
            })
                .catch(err => {
                    console.error('Exercise Status not set', err);
                });
        }

        if(Cookies.get('type') !== 'communityProjects') {
            const titleInput = document.getElementsByClassName('project-title');
            if (exerciseId) {
                titleInput[0].style.cursor = 'not-allowed';
                titleInput[0].disabled = true;
            } else {
                titleInput[0].style.cursor = 'pointer';
                titleInput[0].disabled = false;
            }
            // this.startInterval();
            // document.addEventListener('visibilitychange', this.handleVisibilityChange);
        }
    }

    componentDidUpdate (prevProps) {
        const cookieVal = Cookies.get('location');
        if (cookieVal === 'exercise') {
            if (this.props.exerciseResponse !== prevProps.exerciseResponse) {
                this.setState({
                    exerciseResponse: this.props.exerciseResponse,
                    isExerciseOpen: true,
                    markAsCompletedStatus: Boolean(this.props.exerciseResponse.isExerciseCompleted)
                })
            }
        }
        
    }

    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
        clearInterval(this.autoSaveInterval);
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    startInterval () {
        const fileId = Cookies.get('fileId');
        const exerciseId = Cookies.get('exerciseId');
        if ((fileId !== 'undefined' || exerciseId !== 'undefined') && Cookies.get('type') !== 'exercise') {
            this.autoSaveInterval = setInterval(this.autoSave, 10000);
        } else {
            setTimeout(() => {
                this.setState({
                    isFilenameOpen: true
                });
            }, 4000);
            
        }
    }

    handleVisibilityChange () {
        if (document.hidden) {
            clearInterval(this.autoSaveInterval);
        } else {
            this.startInterval();
        }
    }

    autoSave () {
        if (Cookies.get('exerciseId') !== 'undefined' && Cookies.get('type') === 'newExercises') {
            this.handleSaveExercise(Cookies.get('exerciseId'), true);
        } else {
            if (Cookies.get('type') === 'personalCollection' || Cookies.get('role') === 'mentor' || Cookies.get('type') === 'admin') {
                this.logScratch();
            this.props.saveProjectSb3().then(content => {
                const formData = new FormData();
                formData.append('file', content, Cookies.get('fileName'));
                formData.append('language', 'scratch');
                formData.append('fileId', Cookies.get('fileId'));
                formData.append('type', Cookies.get('type'));
                API.post('/updateScratchFile', formData).then(res => {
                    this.logScratchSuccess();
                })
                    .catch(error => {
                        let title;
                        if (error.response.status === 401) {
                            title = 'Login expired. Please go back to Workbench.';
                        } else {
                            title = 'Unable to save file';
                        }
                        swal(
                            {
                                title: title,
                                icon: 'error'
                            }
                        );
                        console.error('Error While Saving File', error);
                    });
                });
            }
        }
    }
    
    handleClickNew = () => {
        swal({
            title: 'Open a New File?',
            text: 'All unsaved changes will be lost!',
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then(readyToReplaceProject => {
            if (readyToReplaceProject) {
                const sandboxStorage = JSON.parse(localStorage.getItem('sandbox_user'));
                delete sandboxStorage.fileId;
                delete sandboxStorage.type;
                localStorage.setItem('sandbox_user', JSON.stringify(sandboxStorage));
                this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
                Cookies.set('fileId', undefined, {
                    path: '/',
                    domain: '.codeyoung.com'
                });
                this.setState({type: 'personalCollection'});
                Cookies.set('type', 'personalCollection', {
                    path: '/',
                    domain: '.codeyoung.com'
                });
                Cookies.set('exerciseId', undefined, {
                    path: '/',
                    domain: '.codeyoung.com'
                });
                Cookies.set('location', 'sandbox', {
                    path: '/',
                    domain: '.codeyoung.com'
                });
                clearInterval(this.autoSaveInterval);
                window.location.href = `https://scratch.codeyoung.com/?executionId=${this.state.executionId}`;
            }
        });
    }
    handleMarkCompleted = () => {
        swal({
            title: 'Mark Exercise As Completed?',
            icon: 'info',
            buttons: [true, 'Confirm']
        }).then(markAsCompleted => {
            if (markAsCompleted) {
                const exerciseId = Cookies.get('fileId');
                API.post('/submitExercise', {
                    exerciseId,
                    status: 1
                }).then(res => {
                    if (res){
                        // eslint-disable-next-line no-invalid-this
                        this.setState({isCompleted: true});
                        const nextFileId = localStorage.getItem('nextFileId');
                        if (nextFileId) {
                            swal(
                                {
                                    title: 'Exercise Completed!',
                                    icon: 'success',
                                    buttons: ['Close', 'Next Excercise']
                                }
                            )
                                .then(nextExercise => {
                                    if (nextExercise) {
                                        const user = JSON.parse(localStorage.getItem('sandbox_user'));
                                        user.fileId = nextFileId;
                                        localStorage.setItem('sandbox_user', JSON.stringify(user));
                                        Cookies.set('fileId', nextFileId);
                                        window.location.reload();
                                    }
                                });
                        } else {
                            swal(
                                {
                                    title: 'Exercise Completed!',
                                    icon: 'success',
                                    buttons: ['Close', false]
                                }
                            );
                        }
                    }
                })
                    .catch(err => {
                        swal({
                            title: 'Unable to Mark Exercise As Completed!',
                            icon: 'error'
                        });
                        console.error('Error While Marking Exercise As Completed', err);
                    });
            }
        });
    }

    handleReAttemept () {
        if (this.state.exerciseResponse){
            API.post('/reAttempt/scratch', {
                catalogId: this.state.exerciseResponse.catalogId,
                exerciseId: this.state.exerciseResponse.exerciseId
            })
                .then(res => {
                    window.location.reload();
                })
                .catch(e => {
                    swal({
                        title: 'Unable to Reattempt the exercise!',
                        icon: 'error'
                    });
                    console.error("error", e);
                })
        }
    }

    handleClickRemix () {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave () {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy () {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity (waitForUpdate) {
        if (this.props.shouldSaveBeforeTransition()) {
            this.props.autoUpdateProject(); // save before transitioning to project page
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare (waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleRestoreOption (restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress (event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === 's') {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    getSaveToComputerHandler (downloadProjectCallback, fileId) {
        return () => {
            this.props.onRequestCloseFile();
            downloadProjectCallback(fileId);
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
                this.props.onProjectTelemetryEvent('projectDidSave', metadata);
            }
        };
    }

    handleDownloadProject () {
        this.props.saveProjectSb3().then(content => {
            downloadBlob(this.props.projectTitle, content);
        });
    }

    handleLanguageMouseUp (e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    restoreOptionMessage (deletedItem) {
        switch (deletedItem) {
        case 'Sprite':
            return (<FormattedMessage
                defaultMessage='Restore Sprite'
                description='Menu bar item for restoring the last deleted sprite.'
                id='gui.menuBar.restoreSprite'
            />);
        case 'Sound':
            return (<FormattedMessage
                defaultMessage='Restore Sound'
                description='Menu bar item for restoring the last deleted sound.'
                id='gui.menuBar.restoreSound'
            />);
        case 'Costume':
            return (<FormattedMessage
                defaultMessage='Restore Costume'
                description='Menu bar item for restoring the last deleted costume.'
                id='gui.menuBar.restoreCostume'
            />);
        default: {
            return (<FormattedMessage
                defaultMessage='Restore'
                description='Menu bar item for restoring the last deleted item in its disabled state.' /* eslint-disable-line max-len */
                id='gui.menuBar.restore'
            />);
        }
        }
    }
    buildAboutMenu (onClickAbout) {
        if (!onClickAbout) {
            // hide the button
            return null;
        }
        if (typeof onClickAbout === 'function') {
            // make a button which calls a function
            return <AboutButton onClick={onClickAbout} />;
        }
        // assume it's an array of objects
        // each item must have a 'title' FormattedMessage and a 'handleClick' function
        // generate a menu with items for each object in the array
        return (
            <div
                className={classNames(styles.menuBarItem, styles.hoverable, {
                    [styles.active]: this.props.aboutMenuOpen
                })}
                onMouseUp={this.props.onRequestOpenAbout}
            >
                <img
                    className={styles.aboutIcon}
                    src={aboutIcon}
                />
                <MenuBarMenu
                    className={classNames(styles.menuBarMenu)}
                    open={this.props.aboutMenuOpen}
                    place={this.props.isRtl ? 'right' : 'left'}
                    onRequestClose={this.props.onRequestCloseAbout}
                >
                    {
                        onClickAbout.map(itemProps => (
                            <MenuItem
                                key={itemProps.title}
                                isRtl={this.props.isRtl}
                                onClick={this.wrapAboutMenuCallback(itemProps.onClick)}
                            >
                                {itemProps.title}
                            </MenuItem>
                        ))
                    }
                </MenuBarMenu>
            </div>
        );
    }
    wrapAboutMenuCallback (callback) {
        return () => {
            callback();
            this.props.onRequestCloseAbout();
        };
    }

    handleClose () {
        this.setState({
            isExerciseOpen: false
        });
    }

    handleFilenameClose () {
        this.setState({
            isFilenameOpen: false
        });
    }

    handleFileName (name) {
        this.setState({
            fileName: name
        });
    }

    handleSave () {
        this.props.saveProjectSb3().then(content => {
            const formData = new FormData();
            formData.append('file', content, this.state.fileName);
            formData.append('language', 'scratch');
            API.post('/uploadScratchFile', formData).then(res => {
                const lsOb = JSON.parse(localStorage.getItem("sandbox_user"));
                lsOb.fileId = res.data.fileId;
                lsOb.type = res.data.type;
                Cookies.set('fileId', res.data.fileId, {
                    path: '/',
                    domain: '.codeyoung.com'
                });
                Cookies.set('type', 'personalCollection', {
                    path: '/',
                    domain: '.codeyoung.com'
                });
                Cookies.set('fileName', this.state.fileName, {
                    path: '/',
                    domain: '.codeyoung.com'
                });
                localStorage.setItem('sandbox_user', JSON.stringify(lsOb));
                this.props.onSetProjectTitle(this.state.fileName);
                swal(
                    {
                        title: 'File Saved Successfully',
                        icon: 'success'
                    }
                );
                // this.startInterval();
                this.handleFilenameClose();
                this.logScratchSuccess();
            })
                .catch(error => {
                    console.error('Error While Saving File', error);
                });
        });
    }

    handleCloseInstructions () {
        this.setState({
            isInstructionsOpen: false
        });
    }

    onInstructionsClick () {
        this.setState({
            isInstructionsOpen: true
        });
    }

    handleCloseDesiredOutput () {
        this.setState({
            isOutputOpen: false
        });
    }

    onDesiredOutputClick () {
        this.setState({
            isOutputOpen: true
        });
    }

    getBrowserDetails () {
        const userAgent = navigator.userAgent;
        const browser = {};
    
        if (userAgent.match(/chrome|chromium|crios/i)) {
          browser.name = "chrome";
          browser.version = `Chrome v${userAgent.split("Chrome/")[1]}`;
        } else if (userAgent.match(/firefox|fxios/i)) {
          browser.name = "firefox";
          browser.version = `Firefox v${userAgent.split("Firefox/")[1]}`;
        } else if (userAgent.match(/safari/i)) {
          browser.name = "safari";
          browser.version = `Safari v${userAgent.split("Safari/")[1]}`;
        } else if (userAgent.match(/edg/i)) {
          browser.name = "edge";
          browser.version = `Edg v${userAgent.split("Edg/")[1]}`;
        } else {
          browser.name = "No browser detected";
          browser.version = "No Version detected";
        }
        return browser;
      };

    logScratch () {
        const browser = this.getBrowserDetails();
        API.post('/sandboxExecution', {
            language: 'scratch',
            status: 'started',
            platform: 'scartch_ui',
            browser: browser.name,
            browserVersion: browser.version,
            vncId: null,
            executionId: null,
            frontEndSection: Cookies.get('type')
        },
        )
            .then(res => {
                Cookies.set('executionId', res.data.id, {
                    path: '/'
                });
            })
            .catch(e => {
                console.error(e);
            });
    }

    logScratchSuccess () {
        API.put('/sandboxExecution', {
            id: Cookies.get('executionId'),
            status: "completed",
        })
            .then(res => {
              console.log(res);
            })
            .catch((e) => {
              console.error(e);
            });
    }

    onCompletedClick (exerciseId) {
        this.logScratch();
        this.props.saveProjectSb3().then(content => {
            const formData = new FormData();
            formData.append('language', 'scratch');
            formData.append('exerciseId', exerciseId);
            formData.append('catalogId', Cookies.get('catalogueId'));
            formData.append('completedExercise', 1);
            formData.append('file', content, exerciseId);
            API.post('/exercises', formData).then(res => {
                this.logScratchSuccess();
                this.setState({
                    isSuccessOpen: true,
                    markAsCompletedStatus: true
                });
            })
                .catch(error => {
                    if (error.response.status !== 401) {
                        swal(
                            {
                                title: 'Unable to save file',
                                icon: 'error'
                            }
                        );
                    }
                    console.error('Error While Saving File', error);
                });
        });
    }

    handleSaveExercise (exerciseId, isAutosave = false) {
        let url = window.location.href
        var newUrl = new URL(url);
        let submissionId = newUrl.searchParams.get("submissionId");
        if (submissionId && role == "mentor") {
            swal({
                title: "Permission denied to save file",
                icon: "error",
            });
            return;
        }
        this.logScratch();
        this.props.saveProjectSb3().then(content => {

            const formData = new FormData();
            formData.append('language', 'scratch');
            formData.append('exerciseId', exerciseId);
            formData.append('catalogId', Cookies.get('catalogueId'));
            formData.append('file', content, exerciseId);
            API.post('/exercises', formData).then(res => {
                this.logScratchSuccess();
                if (!isAutosave) {
                    swal(
                        {
                            title: 'File Saved Successfully',
                            icon: 'success'
                        }
                    );
                }
            })
                .catch(error => {
                    if (error.response.status !== 401) {
                        swal(
                            {
                                title: 'Unable to save file',
                                icon: 'error'
                            }
                        );
                    }
                    console.error('Error While Saving File', error);
                });
        });
    }

    handleCloseSuccess () {
        this.setState({
            isSuccessOpen: false
        });
    }

    handleNextExercise () {
        let url = window.location.href
        var newUrl = new URL(url);
        let executionId = newUrl.searchParams.get("executionId");
        Cookies.remove('exerciseId');
        const next = this.state.exerciseResponse.nextExercise;
        const id = next.split('/').pop();
        Cookies.set('exerciseId', id, {
            path: '/',
            domain: '.codeyoung.com'
        });
        window.location.replace(`https://scratch.codeyoung.com/?executionId=${executionId}&exerciseId=${id}`);
    }
    render () {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage='Save now'
                description='Menu bar item for saving now'
                id='gui.menuBar.saveNow'
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage='Save as a copy'
                description='Menu bar item for saving as a copy'
                id='gui.menuBar.saveAsCopy'
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage='Remix'
                description='Menu bar item for remixing'
                id='gui.menuBar.remix'
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage='New'
                description='Menu bar item for creating a new project'
                id='gui.menuBar.new'
            />
        );
        const markAsCompletedMessage = (
            <FormattedMessage
                defaultMessage={'Mark As Completed'}
                description='Menu bar item for marking an exercise as completed'
                id='gui.menuBar.markAsCompleted'
            />
        );
        const exerciseCompletedMessage = (
            <FormattedMessage
                defaultMessage={'Exercise Completed'}
                description='Menu bar item for marking an exercise as completed'
                id='gui.menuBar.exerciseCompleted'
            />
        );
        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        // Show the About button only if we have a handler for it (like in the desktop app)
        const aboutButton = this.buildAboutMenu(this.props.onClickAbout);
        const cookieVal = Cookies.get('location');
        return (
            <div>
                {
                    <FilenameModal
                        isFilenameOpen={this.state.isFilenameOpen}
                        onHandleClose={this.handleFilenameClose}
                        onSave={this.handleSave}
                        onFileNameChange={this.handleFileName}
                    />
                }
                {
                    <ExerciseModal
                        exercise={this.state.exerciseResponse}
                        isExerciseOpen={this.state.isExerciseOpen}
                        onHandleClose={this.handleClose}
                    />
                }
                {
                    <InstructionModal
                        instructions={this.state.exerciseResponse.instructions}
                        isInstructionsOpen={this.state.isInstructionsOpen}
                        onHandleClose={this.handleCloseInstructions}
                    />
                }
                {
                    <DesiredOutputModal
                        desiredOutput={this.state.exerciseResponse.desiredOutput}
                        isOutputOpen={this.state.isOutputOpen}
                        onHandleClose={this.handleCloseDesiredOutput}
                    />
                }
                {
                    <SuccessModal
                        nextExercise={this.state.exerciseResponse.nextExercise}
                        isSuccessOpen={this.state.isSuccessOpen}
                        onCloseSuccess={this.handleCloseSuccess}
                        handleNextExercise={this.handleNextExercise}
                        exerciseId={this.state.exerciseId}
                    />
                }
                {cookieVal === 'sandbox' || cookieVal === 'exercise' ?
                    <Box
                        className={classNames(
                            this.props.className,
                            styles.menuBar
                        )}
                    >
                        <div className={styles.mainMenu}>
                            <div className={styles.fileGroup}>
                                <div
                                    className={classNames(styles.menuBarItem)}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => window.location = 'https://sandbox.codeyoung.com'}
                                >
                                    <>Go Back To Sandbox</>
                                </div>
                                <div className={classNames(styles.menuBarItem)}>
                                    <img
                                        alt='Scratch'
                                        className={classNames(styles.scratchLogo, {
                                            [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                        })}
                                        draggable={false}
                                        src={this.props.logo}
                                        onClick={this.props.onClickLogo}
                                    />
                                </div>
                                {/* {(this.props.canChangeLanguage) && (<div
                                    className={classNames(styles.menuBarItem, styles.hoverable, styles.languageMenu)}
                                >
                                    <div>
                                        <img
                                            className={styles.languageIcon}
                                            src={languageIcon}
                                        />
                                        <img
                                            className={styles.languageCaret}
                                            src={dropdownCaret}
                                        />
                                    </div>
                                    <LanguageSelector label={this.props.intl.formatMessage(ariaMessages.language)} />
                                </div>)} */}
                                {(this.props.canManageFiles) && (
                                    <div
                                        className={classNames(styles.menuBarItem, styles.hoverable, {
                                            [styles.active]: this.props.fileMenuOpen
                                        })}
                                        onMouseUp={this.props.onClickFile}
                                    >
                                        <FormattedMessage
                                            defaultMessage='File'
                                            description='Text for file dropdown menu'
                                            id='gui.menuBar.file'
                                        />
                                        <MenuBarMenu
                                            className={classNames(styles.menuBarMenu)}
                                            open={this.props.fileMenuOpen}
                                            place={this.props.isRtl ? 'left' : 'right'}
                                            onRequestClose={this.props.onRequestCloseFile}
                                        >
                                            {/* <MenuSection>
                                                <MenuItem
                                                    isRtl={this.props.isRtl}
                                                    onClick={this.handleClickNew}
                                                >
                                                    {newProjectMessage}
                                                </MenuItem>
                                            </MenuSection> */}
                                            {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                                    <MenuSection>
                                                        {this.props.canSave && (
                                                            <MenuItem onClick={this.handleClickSave}>
                                                                {saveNowMessage}
                                                            </MenuItem>
                                                        )}
                                                        {this.props.canCreateCopy && (
                                                            <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                                {createCopyMessage}
                                                            </MenuItem>
                                                        )}
                                                        {this.props.canRemix && (
                                                            <MenuItem onClick={this.handleClickRemix}>
                                                                {remixMessage}
                                                            </MenuItem>
                                                        )}
                                                    </MenuSection>
                                                )}
                                            {Cookies.get('fileId') !== 'undefined' &&
                                                <MenuSection>
                                                    <SB3Downloader>{(className, downloadProjectCallback) => (
                                                        <MenuItem
                                                            className={className}
                                                            onClick={this.getSaveToComputerHandler(downloadProjectCallback, JSON.parse(localStorage.getItem('sandbox_user')).fileId)}
                                                        >
                                                            Save
                                                        </MenuItem>
                                                    )}</SB3Downloader>
                                                </MenuSection>
                                            }
                                            {this.state.exerciseId !== null &&
                                                <MenuSection>
                                                        <MenuItem
                                                            // className={className}
                                                            onClick={() => this.handleSaveExercise(this.state.exerciseId)}
                                                        >
                                                            Save
                                                        </MenuItem>
                                                </MenuSection>
                                            }
                                            { Cookies.get('location') !== 'exercise' &&
                                            <MenuSection>
                                                <SB3Downloader>{(className, downloadProjectCallback) => (
                                                    <MenuItem
                                                        className={className}
                                                        onClick={this.getSaveToComputerHandler(downloadProjectCallback)}
                                                    >
                                                        Save As
                                                    </MenuItem>
                                                )}</SB3Downloader>
                                            </MenuSection>
                                            }
                                        </MenuBarMenu>
                                    </div>
                                )}
                                {/* <div
                                    className={classNames(styles.menuBarItem, styles.hoverable, {
                                        [styles.active]: this.props.editMenuOpen
                                    })}
                                    onMouseUp={this.props.onClickEdit}
                                >
                                    <div className={classNames(styles.editMenu)}>
                                        <FormattedMessage
                                            defaultMessage='Edit'
                                            description='Text for edit dropdown menu'
                                            id='gui.menuBar.edit'
                                        />
                                    </div>
                                    <MenuBarMenu
                                        className={classNames(styles.menuBarMenu)}
                                        open={this.props.editMenuOpen}
                                        place={this.props.isRtl ? 'left' : 'right'}
                                        onRequestClose={this.props.onRequestCloseEdit}
                                    >
                                        <DeletionRestorer>{(handleRestore, {restorable, deletedItem}) => (
                                            <MenuItem
                                                className={classNames({[styles.disabled]: !restorable})}
                                                onClick={this.handleRestoreOption(handleRestore)}
                                            >
                                                {this.restoreOptionMessage(deletedItem)}
                                            </MenuItem>
                                        )}</DeletionRestorer>
                                        <MenuSection>
                                            <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                                <MenuItem onClick={toggleTurboMode}>
                                                    {turboMode ? (
                                                        <FormattedMessage
                                                            defaultMessage='Turn off Turbo Mode'
                                                            description='Menu bar item for turning off turbo mode'
                                                            id='gui.menuBar.turboModeOff'
                                                        />
                                                    ) : (
                                                        <FormattedMessage
                                                            defaultMessage='Turn on Turbo Mode'
                                                            description='Menu bar item for turning on turbo mode'
                                                            id='gui.menuBar.turboModeOn'
                                                        />
                                                    )}
                                                </MenuItem>
                                            )}</TurboMode>
                                        </MenuSection>
                                    </MenuBarMenu>
                                </div> */}
                            </div>
                            {/* <Divider className={classNames(styles.divider)} /> */}
                            {/* <div
                                aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                                className={classNames(styles.menuBarItem, styles.hoverable)}
                                onClick={this.props.onOpenTipLibrary}
                            >
                                <img
                                    className={styles.helpIcon}
                                    src={helpIcon}
                                />
                                <FormattedMessage {...ariaMessages.tutorials} />
                            </div> */}
                            <Divider className={classNames(styles.divider)} />
                            {this.props.canEditTitle ? (
                                <div className={classNames(styles.menuBarItem, styles.growable)}>
                                    <MenuBarItemTooltip
                                        enable
                                        id='title-field'
                                    >
                                        <ProjectTitleInput
                                            className={classNames(styles.titleFieldGrowable, 'project-title')}
                                        />
                                    </MenuBarItemTooltip>
                                </div>
                            ) : ((this.props.authorUsername && this.props.authorUsername !== this.props.username) ? (
                                <AuthorInfo
                                    className={styles.authorInfo}
                                    imageUrl={this.props.authorThumbnailUrl}
                                    projectTitle={this.props.projectTitle}
                                    userId={this.props.authorId}
                                    username={this.props.authorUsername}
                                />
                            ) : null)}
                        </div>
                        {(
                            <div className={classNames(styles.menuBarItem, styles.rightDiv)}>
                                {
                                    (role === 'student' || role === 'admin') &&
                                    this.state.type === 'exercise' &&
                                    (
                                        <Button
                                            className={classNames(styles.menuBarItem, styles.hoverable, styles.rightButton)}
                                            onClick={this.handleMarkCompleted}
                                            disabled={this.state.isCompleted}
                                            style={{backgroundColor: this.state.isCompleted && 'rgb(49, 212, 62)', opacity: this.state.isCompleted && '1'}}
                                        >
                                            {this.state.isCompleted ? exerciseCompletedMessage : markAsCompletedMessage}
                                        </Button>
                                    )
                                }
                                <span>
                                    <Button
                                        className={classNames(styles.menuBarItem, styles.hoverable, styles.rightButton)}
                                        onClick={this.handleClickNew}
                                    >
                                        <span style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            {newProjectMessage}
                                            <AddCircleOutlineIcon className={styles.icon} />
                                        </span>
                                    </Button>
                                </span>
                            </div>
                        )}
                        <Box>
                            <button
                                className={styles.downloadFile}
                                onClick={this.handleReAttemept}
                            >
                                Reattempt
                                <ReplayIcon className={styles.icon} />
                            </button>
                        </Box>
                        <Box>
                            <button
                                className={styles.downloadFile}
                                onClick={this.handleDownloadProject}
                        >
                                Download
                                <DownloadOutlinedIcon className={styles.icon} />
                            </button>
                        </Box>
                        { (cookieVal === 'exercise') &&
                            <>
                                <Box>
                                    <button
                                        className={styles.downloadFile}
                                        onClick={this.onInstructionsClick}
                                    >
                                        Instructions
                                        <ArticleOutlinedIcon className={styles.icon} />
                                    </button>
                                </Box>
                                <Box>
                                    <button
                                        className={styles.downloadFile}
                                        onClick={this.onDesiredOutputClick}
                                    >
                                        Desired Output
                                        <OutputOutlinedIcon className={styles.icon} />
                                    </button>
                                </Box>
                                {Cookies.get('role') === 'student' &&
                                <Box>
                                    { this.state.markAsCompletedStatus ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                color: '#429F99',
                                                padding: '6px',
                                                background: '#DBF0EF',
                                                borderRadius: '4px',
                                                fontSize: '14px',
                                                alignItems: 'center',
                                                marginTop: '8px',
                                                marginLeft: "5px"
                                            }}
                                        >
                                            <CheckBoxIcon fontSize='small' />
                                            Completed
                                        </div>
                                        ) : (
                                            <button
                                                className={styles.downloadFile}
                                                onClick={() => this.onCompletedClick(this.state.exerciseId)}
                                            >
                                            Mark Completed
                                            <DoneOutlinedIcon className={styles.icon} />
                                        </button>
                                        )}   
                                </Box>
                            }
                            </>
                        }

                        {/* show the proper UI in the account menu, given whether the user is
                        logged in, and whether a session is available to log in with */}
                        <div className={styles.accountInfoGroup}>
                            <div className={styles.menuBarItem}>
                                {this.props.canSave && (
                                    <SaveStatus />
                                )}
                            </div>
                            {this.props.sessionExists ? (
                                this.props.username ? (
                                    // ************ user is logged in ************
                                    <React.Fragment>
                                        <a href='/mystuff/'>
                                            <div
                                                className={classNames(
                                                    styles.menuBarItem,
                                                    styles.hoverable,
                                                    styles.mystuffButton
                                                )}
                                            >
                                                <img
                                                    className={styles.mystuffIcon}
                                                    src={mystuffIcon}
                                                />
                                            </div>
                                        </a>
                                        <AccountNav
                                            className={classNames(
                                                styles.menuBarItem,
                                                styles.hoverable,
                                                {[styles.active]: this.props.accountMenuOpen}
                                            )}
                                            isOpen={this.props.accountMenuOpen}
                                            isRtl={this.props.isRtl}
                                            menuBarMenuClassName={classNames(styles.menuBarMenu)}
                                            onClick={this.props.onClickAccount}
                                            onClose={this.props.onRequestCloseAccount}
                                            onLogOut={this.props.onLogOut}
                                        />
                                    </React.Fragment>
                                ) : (
                                    // ********* user not logged in, but a session exists
                                    // ********* so they can choose to log in
                                    <React.Fragment>
                                        <div
                                            className={classNames(
                                                styles.menuBarItem,
                                                styles.hoverable
                                            )}
                                            key='join'
                                            onMouseUp={this.props.onOpenRegistration}
                                        >
                                            <FormattedMessage
                                                defaultMessage='Join Scratch'
                                                description='Link for creating a Scratch account'
                                                id='gui.menuBar.joinScratch'
                                            />
                                        </div>
                                        <div
                                            className={classNames(
                                                styles.menuBarItem,
                                                styles.hoverable
                                            )}
                                            key='login'
                                            onMouseUp={this.props.onClickLogin}
                                        >
                                            <FormattedMessage
                                                defaultMessage='Sign in'
                                                description='Link for signing in to your Scratch account'
                                                id='gui.menuBar.signIn'
                                            />
                                            <LoginDropdown
                                                className={classNames(styles.menuBarMenu)}
                                                isOpen={this.props.loginMenuOpen}
                                                isRtl={this.props.isRtl}
                                                renderLogin={this.props.renderLogin}
                                                onClose={this.props.onRequestCloseLogin}
                                            />
                                        </div>
                                    </React.Fragment>
                                )
                            ) : (
                                // ******** no login session is available, so don't show login stuff
                                <React.Fragment>
                                    {/* {this.props.showComingSoon ? (
                                        <React.Fragment>
                                            <MenuBarItemTooltip id='mystuff'>
                                                <div
                                                    className={classNames(
                                                        styles.menuBarItem,
                                                        styles.hoverable,
                                                        styles.mystuffButton
                                                    )}
                                                >
                                                    <img
                                                        className={styles.mystuffIcon}
                                                        src={mystuffIcon}
                                                    />
                                                </div>
                                            </MenuBarItemTooltip>
                                            <MenuBarItemTooltip
                                                id='account-nav'
                                                place={this.props.isRtl ? 'right' : 'left'}
                                            >
                                                <div
                                                    className={classNames(
                                                        styles.menuBarItem,
                                                        styles.hoverable,
                                                        styles.accountNavMenu
                                                    )}
                                                >
                                                    <img
                                                        className={styles.profileIcon}
                                                        src={profileIcon}
                                                    />
                                                    <span>
                                                        {'scratch-cat'}
                                                    </span>
                                                    <img
                                                        className={styles.dropdownCaretIcon}
                                                        src={dropdownCaret}
                                                    />
                                                </div>
                                            </MenuBarItemTooltip>
                                        </React.Fragment>
                                    ) : []} */}
                                </React.Fragment>
                            )}
                        </div>

                        {aboutButton}
                    </Box>
                    :
                    <div />}
            </div>
        );
    }
}

MenuBar.propTypes = {
    aboutMenuOpen: PropTypes.bool,
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    onClickAbout: PropTypes.oneOfType([
        PropTypes.func, // button mode: call this callback when the About button is clicked
        PropTypes.arrayOf( // menu mode: list of items in the About menu
            PropTypes.shape({
                title: PropTypes.string, // text for the menu item
                onClick: PropTypes.func // call this callback when the menu item is clicked
            })
        )
    ]),
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    onRequestOpenAbout: PropTypes.func,
    onRequestCloseAbout: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    userOwnsProject: PropTypes.bool,
    username: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    saveProjectSb3: PropTypes.func,
    onSetExerciseContent: PropTypes.func,
    onSetProjectTitle: PropTypes.func
};

MenuBar.defaultProps = {
    logo: scratchLogo,
    onShare: () => {}
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        aboutMenuOpen: aboutMenuOpen(state),
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        username: user ? user.username : null,
        userOwnsProject: ownProps.authorUsername && user &&
            (ownProps.authorUsername === user.username),
        vm: state.scratchGui.vm,
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm)
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onRequestOpenAbout: () => dispatch(openAboutMenu()),
    onRequestCloseAbout: () => dispatch(closeAboutMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onSetExerciseContent: exercise => dispatch(setExerciseContent(exercise)),
    onSetProjectTitle: title => dispatch(setProjectTitle(title))
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuBar);
