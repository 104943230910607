import PropTypes from 'prop-types';
import React from 'react';
import {Button, Grid, Dialog} from '@material-ui/core';
import Close from './close-btn.png';
import bindAll from 'lodash.bindall';
import {withStyles} from '@material-ui/core/styles';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import Completed from './illustration_view.png';
import ExerciseRatings from './exercise-ratings.jsx';

const styles = {
    main: {
        width: '874px',
        height: 'auto',
        padding: '20px 30px'
    },
    heading: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '29px',
        color: '#707070',
        marginBottom: '10px',
        marginTop: '0px'
    },

    description: {
        fontWeight: '500',
        color: '#707070',
        marginBottom: '10px',
        fontSize: '16px',
        lineHeight: '19px'
    },
    next: {
        display: 'flex',
        marginTop: '95px',
        marginTtop: '40px'
    },
    nextBtn: {
        background: '#FF712D',
        color: '#FFFFFF',
        position: 'absolute',
        bottom: '28px',
        right: '28px'
    },
    close: {
        position: 'absolute',
        top: '20px',
        right: '2px'
    },
    instructionsWrapper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    points: {
        color: '#959595',
        fontSize: '14px',
        wordBreak: 'break-word'
    },
    ratings: {
        marginTop: '20px'
    }
};

class InstructionsModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleNextExercise'
        ]);
    }

    handleClose () {
        this.props.onCloseSuccess();
    }

    handleNextExercise () {
        this.props.handleNextExercise()
    }

    render () {
        const { main, close, heading, description, next, nextBtn, instructionsWrapper, ratings } = this.props.classes;
        const nextExercise = this.props.nextExercise;

        return (
            <Dialog
                open={this.props.isSuccessOpen}
                maxWidth={'md'}
                onClose={this.handleClose}
            >
                <Grid container>
                    <Grid item>
                        <div className={main}>
                            <div>
                                <Button
                                    variant='text'
                                    className={close}
                                    onClick={this.handleClose}
                                >
                                    <img width='15px' src={Close} alt='close' />
                                </Button>
                            </div>
                            <div className={instructionsWrapper}>
                                <div className={heading}>Congrats!</div>
                                <p className={description}>
                                    You have successfully completed the exercise.
                                </p>
                                <img width='200px' src={Completed} alt='img' />
                                <div className={ratings}>
                                    <ExerciseRatings exerciseId={this.props.exerciseId} />
                                </div>
                                {nextExercise && (
                                    <div className={next}>
                                    <Button
                                        variant='outlined'
                                        className={nextBtn}
                                        onClick={this.handleNextExercise}
                                    >
                                        Next Exercise {<SkipNextIcon />}
                                    </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
        </Dialog>
    )
}
};

InstructionsModal.propTypes = {
    nextExercise: PropTypes.string,
    isSuccessOpen: PropTypes.bool,
    onCloseSuccess: PropTypes.func.isRequired
};

export default withStyles(styles)(InstructionsModal);
