import PropTypes from 'prop-types';
import React from 'react';
import {Button, Divider, Grid, Dialog} from '@material-ui/core';
import Star from './concepts-icon.png';
import Close from './close-btn.png';
import bindAll from 'lodash.bindall';
import {withStyles} from '@material-ui/core/styles';
import ExerciseRatings from './exercise-ratings.jsx';

const styles = {
    main: {
        padding: '68px 80px',
        width: '874px',
        height: '330px'
    },
    container: {
        background: '#FFFFFF',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row'
    },
    close: {
        position: 'absolute',
        top: '10px',
        right: '2px'
    },
    content: {
        paddingRight: '12px',
        marginLeft: '40px'
    },
    heading: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '12px',
        color: '#707070'
    },
    description: {
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '22px',
        color: '#8F8F8F'
    },
    concepts: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#707070',
        marginBottom: '16px',
        marginTop: '40px'
    },
    image: {
        width: '55px',
        height: '55px'
    },
    levelBox: {
        display: 'flex',
        marginTop: '15px'
    },
    level: {
        background: '#CCDAFF',
        display: 'block',
        marginLeft: '2px',
        width: '25px',
        height: '8px'
    },
    easyStyle: {
        background: '#4AB3AC'
    },
    mediumStyle: {
        background: '#FAB44B'
    },
    hardStyle: {
        background: '#FA6F4B'
    },
    contentInnerImgBox: {
        width: '25%',
        display: 'flex',
        justifyContent: 'center'
    },
    contentBoxImg: {
        width: '80px',
        height: '80px'
    },
    levelText: {
        fontWeight: '400',
        fontSize: '9px',
        lineHeight: '12px',
        color: '#7D7D7D',
        marginTop: '4px'
    },
    courseInclude: {
        margin: '6px 5px 0px 0px',
        borderRadius: '4px',
        background: '#E2EFFD',
        color: '#508CCE',
        fontWeight: '500',
        padding: '8px',
        maxHeight: '38px'
    },
    conceptText: {
        marginLeft: '6px'
    },
    start: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '40px'
    },
    startBtn: {
        width: '129px',
        height: '39px',
        background: '#FF712D',
        color: '#FFFFFF',
        marginTop: '1vh'
    },
    courseIncludeBox: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    ratings: {
        marginBottom: '15px'
    }
};

class ExerciseModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose'
        ]);
    }

    handleClose () {
        this.props.onHandleClose();
    }

    render () {
        const {main, container, close, content, heading, description, concepts, image, levelBox, level, easyStyle, mediumStyle, hardStyle, contentInnerImgBox, contentBoxImg, levelText, courseInclude, conceptText, start, startBtn, courseIncludeBox, ratings } = this.props.classes;
        const exercise = this.props.exercise;
        const easy = exercise.difficulty === 'Easy' ? easyStyle : null;
        const medium = exercise.difficulty === 'Medium' ? mediumStyle : null;
        const hard = exercise.difficulty === 'Hard' ? hardStyle : null;

        return (
            <Dialog
                open={this.props.isExerciseOpen}
                maxWidth={'md'}
                onClose={this.handleClose}
            >
                <Grid container>
                    <Grid item>
                        <div className={main}>
                            <Button
                                variant='text'
                                className={close}
                                onClick={this.handleClose}
                            >
                                <img width='15px' src={Close} alt='close' />
                            </Button>
                            <div className={container}>
                                <div className={contentInnerImgBox}>
                                    <div>
                                        <img
                                            src={exercise.exerciseImg}
                                            alt='Rocket'
                                            className={contentBoxImg}
                                        />
                                        <div className={levelBox}>
                                            <span
                                                className={`${level} ${easy} ${medium} ${hard}`}
                                            ></span>
                                            <span
                                                className={`${level} ${medium} ${hard}`}
                                            ></span>
                                            <span className={`${level} ${hard}`}></span>
                                        </div>
                                        <p className={levelText}>
                                        Level : {exercise.difficulty}
                                        </p>
                                    </div>
                                </div>
                                <div className={content}>
                                    <div className={heading}>
                                        {exercise.exerciseTitle}
                                    </div>
                                    <p className={description}>
                                        {exercise.description}
                                    </p>
                                        <>
                                        <Divider />
                                        <div className={concepts}>Concepts Covered</div>
                                        <div className={courseIncludeBox}>
                                            {exercise?.conceptLearnt &&
                                            exercise?.conceptLearnt.split(",").map((c) => {
                                                return (
                                                <span className={courseInclude}>
                                                    <img src={Star} alt='Star' />
                                                    <span className={conceptText}>
                                                    {c}
                                                    </span>
                                                </span>
                                                );
                                            })}
                                        </div>
                                        </>
                                </div>
                            </div>
                        </div>
                        {exercise?.isExerciseCompleted == 1 && 
                        (<div className={ratings}>
                            <ExerciseRatings 
                                exerciseId={exercise?.exerciseId}
                                exerciseRating={exercise?.exerciseRating}
                            />
                        </div>)}
                        <div className={start}>
                            <Button
                                variant='outlined'
                                className={startBtn}
                                onClick={this.handleClose}
                            >
                                Start
                            </Button>
                        </div>
                    </Grid>
                </Grid>
        </Dialog>
    )
}
};

ExerciseModal.propTypes = {
    exercise: PropTypes.object.isRequired,
    isExerciseOpen: PropTypes.bool,
    onHandleClose: PropTypes.func.isRequired
};

export default withStyles(styles)(ExerciseModal);
