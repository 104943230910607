/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-negated-condition */
/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable indent */
import React, {useState} from "react";
import {Input, InputLabel, makeStyles} from "@material-ui/core";
import StarOutline from "@material-ui/icons/StarOutline";
import Star from "@material-ui/icons/Star";
import {API} from "../../services/api-service";

const useStyles = makeStyles(theme => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "20px",
    backgroundColor: "#e0e0e0",
    width: "220px",
    height: "70px",
    margin: "auto"
  },
  lightMode: {
    background: "#E9ECEC"
  },
  stars: {
    display: "flex",
    direction: "row"
  },
  star: {
    cursor: "pointer",
    transition: "color 200ms",
    color: "#91B6B6",
    width: "28px",
    height: "28px"
  },
  text: {
    marginTop: "5px",
    fontSize: "12px",
    fontFamily: "Inter",
    lineHeight: "12px",
    color: "#607d8b"
  },
  orange: {
    color: "#E98451"
  }
}));

const ExerciseRatings = ({exerciseId, exerciseRating}) => {
  const classes = useStyles();
  const [rating, setRating] = useState(exerciseRating || 0);
  const [hover, setHover] = useState(0);
  const [updateRating, setUpdateRating] = useState(
    !!exerciseRating
  );

  const handleRating = ratingValue => {
    if (exerciseRating != ratingValue) {
      setRating(ratingValue);
      API.post("/exercise/ratings", {
        exerciseId,
        rating: ratingValue
      })
        .then(res => {
          setUpdateRating(true);
        })
        .catch(err => {
          console.log("Error while updationg rating: ", err);
        });
    }
  };

  return (
      <div>
          <div
              className={`${classes.card} ${classes.lightMode}`}
          >
              <div className={classes.stars}>
                  {[...Array(5)].map((star, index) => {
            const ratingValue = index + 1;

            return (
                <InputLabel key={index}>
                    <Input
                        type="radio"
                        name="rating"
                        value={ratingValue}
                        style={{display: "none"}}
                        onClick={() => {
                    handleRating(ratingValue);
                  }}
                    />
                    {ratingValue <= (hover || rating) ? (
                        <Star
                            className={`${classes.star} ${classes.orange}`}
                            onMouseEnter={() => setHover(ratingValue)}
                            onMouseLeave={() => setHover(0)}
                        />
                ) : (
                    <StarOutline
                        className={classes.star}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(0)}
                    />
                )}
                </InputLabel>
            );
          })}
              </div>
              {updateRating ? (
                  <div className={classes.text}>Thanks for your rating!</div>
        ) : (
            <div className={classes.text}>Enjoyed this exercise? Rate it.</div>
        )}
          </div>
      </div>
  );
};

export default ExerciseRatings;
