import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = {
    BACKPACK_ITEM: "backpackItem",
};

const BackpackItem = ({ item, index, moveItem, removeItem }) => {
    const [, ref] = useDrag({
        type: ItemType.BACKPACK_ITEM,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemType.BACKPACK_ITEM,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <li
            ref={(node) => ref(drop(node))}
            style={{ marginBottom: "5px", cursor: "move" }}
        >
            {item}
            <button
                onClick={() => removeItem(index)}
                style={{ marginLeft: "10px", color: "red" }}
            >
                Remove
            </button>
        </li>
    );
};

const Backpack = () => {
    const [backpackItems, setBackpackItems] = useState(() => {
        const savedItems = localStorage.getItem("backpackItems");
        return savedItems ? JSON.parse(savedItems) : [];
    });
    const [itemToAdd, setItemToAdd] = useState("");

    const handleAddItem = () => {
        if (itemToAdd.trim()) {
            const updatedItems = [...backpackItems, itemToAdd];
            setBackpackItems(updatedItems);
            localStorage.setItem("backpackItems", JSON.stringify(updatedItems));
            setItemToAdd("");
        }
    };

    const handleRemoveItem = (index) => {
        const updatedItems = backpackItems.filter((_, i) => i !== index);
        setBackpackItems(updatedItems);
        localStorage.setItem("backpackItems", JSON.stringify(updatedItems));
    };

    const moveItem = (fromIndex, toIndex) => {
        const updatedItems = [...backpackItems];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        setBackpackItems(updatedItems);
        localStorage.setItem("backpackItems", JSON.stringify(updatedItems));
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                style={{
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                }}
            >
                <h2>Backpack</h2>
                <div style={{ marginBottom: "10px" }}>
                    <input
                        type="text"
                        value={itemToAdd}
                        onChange={(e) => setItemToAdd(e.target.value)}
                        placeholder="Add code block or item"
                        style={{ marginRight: "5px" }}
                    />
                    <button onClick={handleAddItem}>Add to Backpack</button>
                </div>

                <ul>
                    {backpackItems.map((item, index) => (
                        <BackpackItem
                            key={index}
                            item={item}
                            index={index}
                            moveItem={moveItem}
                            removeItem={handleRemoveItem}
                        />
                    ))}
                </ul>
            </div>
        </DndProvider>
    );
};

export default Backpack;
